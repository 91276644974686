<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold color-pink center ${isMobile ? 'font-15' : 'font-20'}`">EVENTBOX</span>
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ $t('work_with_us') }}</span>
    <span :class="`center ${isMobile ? 'mt-5 font-15' : 'mt-10 font-20'}`">{{ $t('at_eventbox_you_grow_in_strong_team') }}</span>
    <div :class="`d-flex flex-column ${isWide ? 'm-30 font-25' : isMobile ? 'm-5 font-15' : 'm-10 font-20'}`">
      <img class="w-100" :src="assets.job_img" />
      <span :class="isWide ? 'mt-20' : 'mt-5'">&nbsp;</span>
      <div class="m-5 job-div d-flex flex-column" v-for="(job, index) in jobs" :key="(job, index)" @click="$router.push(`/jobs_detail/${index}`)">
        <div class="d-flex justify-content-between">
          <span :class="`font-bold ${isMobile ? 'font-15' : 'font-20'}`">{{ job.title }}</span>
          <span :class="`box-pink-light color-pink ${isMobile ? 'font-10' : 'font-12'}`">{{ job.location }}</span>
        </div>
        <span :class="`font-bold ${isMobile ? 'my-5 font-12' : 'my-10 font-15'}`">{{ job.content }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.job-div {
	padding: 15px;
  cursor: pointer;
	background: white !important;
  border: 1px solid #EAECF0 !important;
	border-radius: 10px !important;
}
</style>

<script>
import job_img from '@/assets/images/job_img.png';

export default {
  name: 'JobList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  },
  data() {
    return {
      assets: {
        job_img
      },
      jobs: [
        {
          title: '- Country Manager',
          content: 'Operations / Experienced',
          location: 'Mumbai'
        },
        {
          title: '- Social Media Content Manager',
          content: 'Intern / Experienced',
          location: 'München'
        },
        {
          title: '- Marketing Manager',
          content: 'Operations / Experienced',
          location: 'München'
        },
        {
          title: '- Creator & Content Strategies',
          content: 'Operations / Advanced',
          location: 'München'
        },
        {
          title: '- Marketing Manager',
          content: 'Operations / Experienced',
          location: 'Berlin'
        },
        {
          title: '- Creator & Content Strategies',
          content: 'Operations / Advanced',
          location: 'Istanbul'
        },
        {
          title: '- Partnerships Manager',
          content: 'Sales / Experienced',
          location: 'Istanbul'
        },
        {
          title: '- Product Manager',
          content: 'Product / Experienced',
          location: 'Los Angeles'
        },
        {
          title: '- Art Director/Designer',
          content: 'Design / Experienced',
          location: 'Los Angeles'
        },
        {
          title: '- Video Editor',
          content: 'Marketing / Experienced',
          location: 'Berlin'
        }
      ]
    };
  }
};
</script>
